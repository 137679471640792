























































import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';

@Component
export default class ContactSupportComponent extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  @Prop({ required: false, default: '' })
  public readonly background!: string;

  @Prop({ required: false, default: '' })
  public readonly topGap!: string;

  @Prop({ required: false, default: '' })
  public readonly bottomGap!: string;

  @Prop({ required: false, default: '' })
  private readonly title!: string;

  @Prop({ required: false, default: '' })
  private readonly subtitle!: string;

  @Prop({ required: false, default: '' })
  private readonly btnTitle!: string;

  @Prop({ required: false, default: '' })
  private readonly btnRoute!: string;

  private readonly avatars = [
    '/img/users/user_1.png',
    '/img/users/user_2.png',
    '/img/users/user_3.png',
  ];

  created(): void {
    this.setDataConfig();
  }
}
